<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#7CB9E8" top :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center" style="font-size: 18px; font-family: montserratSemiBold">{{ msg
        }}</v-flex>
        <v-flex text-right>
          <v-btn :ripple="false" text @click="showSnackBar = false">
            <span style="font-size: 18px; font-family: montserratExtraBold">Ok
            </span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs10 sm8 text-center pt-6 pb-6>
        <span style="font-family: sedanRegular; color: #000000; font-size: 20px">TRANSFER ASSET
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center px-10>
      <v-flex xs10 sm2 md2 text-center>
        <span style="
                                  font-family: montserratSemiBold;
                                  color: #000000;
                                  font-size: 15px;
                                ">{{ asset.assetID }}
        </span>
      </v-flex>
      <v-flex xs10 sm10 md10 text-left>
        <span style="
                                  font-family: montserratSemiBold;
                                  color: #000000;
                                  font-size: 15px;
                                ">{{ asset.assetName }}
        </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md10 text-left pt-3>
        <span style="font-family: sedanRegular; color: #000000; font-size: 18px">PRIVATE FILES
        </span>
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card>
              <v-layout wrap justify-center py-6 pa-5 px-15>
                <!-- <v-flex
                  xs12
                  sm12
                  style="border-style: dotted; border-color: #7cb9e8"
                >
                  <v-card outlined color="#F6FBFF">
                    <v-layout wrap justify-center>
                      <v-flex xs6 sm4 md2 text-center align-self-center pa-6>
                        <v-img
                          @click="$refs.uploadDoc.click()"
                          contain
                          style="cursor: pointer"
                          src="./../../assets/icons/UploadIcon.png"
                        ></v-img>
                      </v-flex> -->

                <v-flex xs10 md6 lg12 align-self-center text-center pb-2 style="
                                          border-style: dotted;
                                          border-color: #7cb9e8;
                                          background-color: #f6fbff;
                                        ">
                  <v-layout wrap justify-center pa-2>
                    <v-icon small @click="$refs.uploadDoc.click()" color="black"
                      style="font-family: montserratBlack; font-size: 17px">mdi-plus</v-icon>
                    <span style="font-family: montserratBold; font-size: 15px">Upload your files</span>
                    <input v-show="false" id="file1" ref="uploadDoc" multiple type="file" @change="uploadDoc" />
                  </v-layout>
                </v-flex>
                <!-- </v-layout>
                  </v-card>
                </v-flex> -->
                <v-layout wrap justify-start>
                  <v-flex xs12 sm6 pt-2 v-for="(items, j) in docs" :key="j">
                    <!-- docs.splice(j, 1) -->
                    <v-chip @click:close="removePrivate(j)" class="ma-1" close outlined color="#7CB9E8"
                      text-color="#929292">
                      <v-layout wrap justify-center>
                        <v-flex xs6 text-left pa-2>
                          <span>{{ items.name.slice(0, 20) }}
                            <span v-if="items.name.length > 20">
                              ...
                            </span></span>
                        </v-flex>
                        <v-flex xs6 text-right pa-2>
                          {{ items.sizeCopy }}</v-flex>
                      </v-layout>
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-layout>
              <v-layout wrap justify-center px-3>
                <v-flex xs11>
                  <v-layout wrap>
                    <v-flex xs6>
                      <span style="font-family: sedanRegular; font-size: 13px">Files loaded here can be accessed ONLY BY
                        SELECTED
                        ECOLedger users</span>
                    </v-flex>
                    <v-flex xs5>
                      <v-btn color="#D2E1ED" small :ripple="false" depressed @click="dialog = true">
                        <span style="font-family: sedanRegular; font-size: 12px">SELECT</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex xs6 sm4 v-for="(item1, j) in newArray" :key="j">
                      <v-chip class="ma-1" outlined>
                        <span style="font-family: sedanRegular; font-size: 16px">
                          {{ item1 }}</span>
                      </v-chip>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start py-3>
                    <!-- {{ this.manage.ownershipTransferringTo }} -->
                    <v-flex v-if="manage.ownershipTransferringTo">
                      <span style="font-family: sedanRegular; font-size: 14px">
                        <i>* By default, <span style="font-weight:bold">{{ manage.nameOwner }}</span> and <span
                            style="font-weight:bold">{{ manage.ownerUid.name }}</span> will
                          have access to the private files </i>
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start pb-5>
                    <v-flex xs1 sm1 pt-2>
                      <v-checkbox color="#7CB9E8" v-model="checkbox" :hide-details="true"></v-checkbox></v-flex>
                    <v-flex xs10 sm11 text-left pt-2>
                      <span style="font-family: sedanRegular; font-size: 14px">I have confirmed that the included files
                        are the
                        correct ones and have been appropriately named
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs10 sm10 md10 text-left pt-3>
        <span style="font-family: sedanRegular; color: #000000; font-size: 18px">PUBLIC FILES
        </span>
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card>
              <v-layout wrap justify-center py-6 pa-5 px-15>
                <!-- <v-flex
                  xs12
                  sm12
                  align-self-center
                  style="border-style: dotted; border-color: #7cb9e8"
                >
                  <v-card outlined color="#F6FBFF">
                    <v-layout wrap justify-center>
                      <v-flex xs6 sm4 md2 text-center align-self-center pa-6>
                        <v-img
                          contain
                          @click="$refs.uploadDocPublic.click()"
                          style="cursor: pointer"
                          src="./../../assets/icons/UploadIcon.png"
                        ></v-img>
                      </v-flex> -->

                <v-flex xs10 md6 lg12 align-self-center text-center pb-2 style="
                                          border-style: dotted;
                                          border-color: #7cb9e8;
                                          background-color: #f6fbff;
                                        ">
                  <v-layout wrap justify-center pa-2>
                    <v-icon small @click="$refs.uploadDocPublic.click()" color="black"
                      style="font-family: montserratBlack; font-size: 17px">mdi-plus</v-icon>
                    <span style="font-family: montserratBold; font-size: 15px">Upload your files</span>
                    <input v-show="false" id="file" ref="uploadDocPublic" multiple type="file"
                      @change="uploadDocPublic" />
                  </v-layout>
                </v-flex>
                <!-- </v-layout>
                  </v-card>
                </v-flex> -->
                <v-layout wrap justify-start>
                  <v-flex xs12 sm6 pt-2 v-for="(list, i) in docsPublic" :key="i">
                    <!-- docsPublic.splice(i, 1) -->
                    <v-chip @click:close="removePublic(i)" class="ma-2" close outlined color="#7CB9E8"
                      text-color="#929292">
                      <v-layout wrap justify-center>
                        <v-flex xs6 text-left pa-2>
                          <span>{{ list.name.slice(0, 20) }}
                            <span v-if="list.name.length > 20">
                              ...
                            </span></span></v-flex>
                        <v-flex xs6 text-right pa-2>
                          {{ list.sizeCopy }}</v-flex>
                      </v-layout>
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-layout>
              <v-layout wrap justify-center px-3>
                <v-flex xs11>
                  <v-layout wrap justify-start>
                    <v-flex xs1 sm1 pt-2>
                      <v-checkbox color="#7CB9E8" v-model="checkbox1" :hide-details="true"></v-checkbox></v-flex>
                    <v-flex xs10 sm11 text-left pt-2>
                      <span style="font-family: sedanRegular; font-size: 14px">I am okay for the files selected above to
                        be viewed by
                        ALL ECOLedger users
                      </span>
                    </v-flex>
                  </v-layout>
                  <v-layout wrap justify-start pb-5>
                    <v-flex xs1 sm1 pt-2>
                      <v-checkbox color="#7CB9E8" v-model="checkbox2" :hide-details="true"></v-checkbox></v-flex>
                    <v-flex xs10 sm10 text-left pt-2>
                      <span style="font-family: sedanRegular; font-size: 14px">I have confirmed that the included files
                        are the
                        correct ones and are okay to make public
                      </span>
                    </v-flex>
                  </v-layout></v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-layout wrap justify-center py-5>
            <v-flex xs1 sm1 pt-2>
              <v-checkbox color="#7CB9E8" v-model="checkbox3" :hide-details="true"></v-checkbox></v-flex>
            <v-flex xs10 sm11 text-left pt-2>
              <span style="font-family: sedanRegular; font-size: 14px">
                <!-- I have reviewed all submitted data and have the necessary
                approvals to commit to the Blockchain -->
                Note that once this transaction is registered, it will be
                permanently recorded on the blockchain. Please do not expose
                information (data and files) that are confidential. This cannot
                be undone
              </span>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center py-2>
            <!-- <v-flex xs6 sm5>
              <v-btn @click="validate()" v-if="checkbox3" rounded block color="#7CB9E8" dark>
                <span style="font-size: 12px; font-family: montserratSemiBold">Transfer</span>
              </v-btn>
            </v-flex> -->
            <v-flex xs6 sm5 v-if="!this.docsPublic.length > 0 && !this.docs.length > 0">
              <v-btn @click="validate()" v-if="checkbox3" rounded block color="#7CB9E8" dark>
                <span style="font-size: 12px; font-family: montserratSemiBold">Transfer</span>
              </v-btn>
            </v-flex>
            <v-flex xs6 sm5 v-if="this.docsPublic.length > 0 && !this.docs.length > 0">
              <v-btn @click="validate()" v-if="checkbox1 && checkbox2 && checkbox3" rounded block color="#7CB9E8" dark>
                <span style="font-size: 12px; font-family: montserratSemiBold">Transfer</span>
              </v-btn>
            </v-flex>
            <v-flex xs6 sm5 v-if="this.docsPublic.length > 0 && this.docs.length > 0">
              <v-btn @click="validate()" v-if="checkbox && checkbox1 && checkbox2 && checkbox3" rounded block
                color="#7CB9E8" dark>
                <span style="font-size: 12px; font-family: montserratSemiBold">Transfer</span>
              </v-btn>
            </v-flex>
            <v-flex xs6 sm5 v-if="!this.docsPublic.length > 0 && this.docs.length > 0">
              <v-btn @click="validate()" v-if="checkbox && checkbox3" rounded block color="#7CB9E8" dark>
                <span style="font-size: 12px; font-family: montserratSemiBold">Transfer</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-layout wrap justify-center py-4>
          <v-flex xs12 text-center>
            <span style="font-family: sedanRegular; font-size: 20px">SELECT USERS</span>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center pb-5>
          <v-flex xs10>
            <!-- <v-autocomplete
              label="Search here"
              outlined
              dense
              multiple
              v-model="keyword"
              :items="userList"
              item-text="name"
              item-value="_id"
              @input="addUser(userList)"
              :hide-details="true"
            ></v-autocomplete> -->
            <v-combobox label="Select" v-model="keyword" outlined color="#FF1313" multiple :items="userList" dense
              :search-input.sync="search" item-text="name" item-value="_id" hide-details small-chips>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <span class="pa-1">
                  <v-chip v-bind="attrs" :input-value="selected" small>
                    <span class="pr-2" style="font-family: opensansregular; font-size: 13px">
                      {{ item.name }} </span>&nbsp;
                    <v-icon small @click="parent.selectItem(item)">
                      mdi-close-circle
                    </v-icon>
                  </v-chip>
                </span>
              </template>
              <template v-slot:append>
                <span></span>
              </template>
            </v-combobox>
          </v-flex>
          <!-- :search-input.sync="search" -->
          <!-- <v-flex xs2>
            <v-btn color="#7CB9E8" @click="getMap(keyword)">
              <v-icon color="white">mdi-magnify</v-icon>
            </v-btn>
          </v-flex> -->
        </v-layout>
        <!-- <v-layout wrap pb-5>
          <v-flex xs6 sm4 v-for="(item, i) in newArray" :key="i">
            <v-chip
              class="ma-1"
              close
              outlined
              @click:close="newArray.splice(i, 1), userArray.splice(i, 1)"
            >
              <span style="font-family: sedanRegular; font-size: 16px">
                {{ item }}</span
              >
            </v-chip>
          </v-flex>
        </v-layout> -->
        <v-layout wrap justify-center pb-8>
          <v-flex xs4>
            <v-btn color="#7CB9E8" rounded block dark @click="addUser(), saveDialog()">
              <span style="font-family: sedanRegular">save</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogImage" width="500" persistent>
      <v-card>
        <v-layout wrap justify-center py-4 pa-2>
          <!-- <v-flex xs12 text-center v-if="!this.docsPublic.length > 0 && !this.docs.length > 0">
            <span style="font-family: sedanRegular; font-size: 20px">Private and Public files are not choosed. <br> Do you
              want to continue ?</span>
          </v-flex>
          <v-flex xs12 text-center v-if="!this.docsPublic.length > 0 && this.docs.length > 0">
            <span style="font-family: sedanRegular; font-size: 20px">Public files is not choosed. <br> Do you want to
              continue ?</span>
          </v-flex>
          <v-flex xs12 text-center v-if="!this.docs.length > 0 && this.docsPublic.length > 0">
            <span style="font-family: sedanRegular; font-size: 20px">Private files is not choosed. <br> Do you want to
              continue ?</span>
          </v-flex> -->
          <v-flex xs12 text-center>
            <span style="font-family: sedanRegular; font-size: 20px">No private or public files chosen. Do you wish to
              continue?</span>
          </v-flex>
        </v-layout>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogImage = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="Register()"><span style="font-family: sedanRegular">Ok</span></v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogMessage" width="500" persistent>
      <v-card>
        <v-layout wrap justify-center py-4 pa-2>
          <v-flex xs12 text-center py-4>
            <v-icon color="green" size="50px">mdi-check-circle</v-icon>
          </v-flex>
          <v-flex xs12 text-center>
            <span style="font-family: sedanRegular; font-size: 20px; color:black" v-html="newMsg"></span>
          </v-flex>
        </v-layout>

        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="dialogMessage = false">Cancel</v-btn> -->
          <v-btn color="blue darken-1" text @click.native="$router.push('/assetList')"><span
              style="font-family: sedanRegular">Ok</span></v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: [
    "asset",
    "manage",
    "species",
    "assetIdNew",
    "subLevel",
    "gpsLocationCodes",
    "speciesFlora",
    "speciesFauna",
    "speciesOther",
    "speciesAdditionalComments",
    "users",
  ],
  data: () => ({
    appLoading: false,
    ServerError: false,
    showSnackBar: false,
    msg: null,
    timeout: 5000,
    check: false,
    checkbox: false,
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
    checkbox4: false,
    checkbox5: false,
    checkbox6: false,
    checkbox7: false,
    checkbox8: false,
    checkbox9: false,
    checkbox10: false,
    checkbox11: false,
    dialog: false,
    dialogCheck: false,
    dialogImage: false,
    dialogMessage: false,
    docs1: [],
    docs1Public: [],
    docsPublic: [],
    docs: [],
    userList: [],
    userArray: [],
    newArray: [],
    doc: null,
    file: null,
    keyword: "",
    registerAssets: {},
    formData: new FormData(),
    assetId: "",
    search: "",
    fileArray: [],
    Rules: [(value) => !!value || "Required."],
    byte: "",
    kb: "",
    mb: "",
    gb: "",
    newMsg: "",
  }),
  watch: {
    search(key) {
      axios({
        method: "GET",
        url: "/assets/encryptFiles/users/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          keyword: key,
        },
      })
        .then((response) => {
          if (response.data.status) {
            this.userList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    subLevel: [
      {
        handler: "getTab",
      },
    ],
  },
  mounted() {
    this.getUsers();
    this.assetId = this.assetIdNew;
    // this.keyword = this.users;
    this.keyword = this.users.filter((x) => (x._id.toString() != this.manage.ownerUid._id.toString()) && (x._id.toString() != this.manage.ownershipTransferringTo.toString()));
    this.userArray = this.keyword.map((x) => x._id);
    this.newArray = this.keyword.map((x) => x.name);
  },
  methods: {
    validate() {
      if (!this.userArray.length > 0 && this.docs.length > 0) {
        this.msg = "No users selected for private files";
        this.showSnackBar = true;
      }
      if (!this.docsPublic.length > 0 || !this.docs.length > 0) {
        this.dialogImage = true;
      } else {
        this.Register();
      }
    },
    getTab() {
      if (
        this.subLevel.includes(1) ||
        this.subLevel.includes(2) ||
        this.subLevel.includes(3)
      ) {
        // this.checkbox = false;
        // this.checkbox1 = false;
        // this.checkbox2 = false;
        this.checkbox3 = false;
      }
    },
    getUsers() {
      axios({
        method: "GET",
        url: "/assets/encryptFiles/users/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status) {
            var newList = response.data.data;
            this.userList = newList.filter((x) => (x._id.toString() != this.manage.ownershipTransferringTo.toString()))
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addUser() {
      // if (!this.userArray.includes(this.keyword)) {
      // this.userArray.push(this.keyword);
      this.userArray = this.keyword.map((x) => x._id);
      this.newArray = this.keyword.map((x) => x.name);
      // var newData = item.filter((x) => x._id == this.keyword);
      // this.newArray.push(newData[0].name);
      // this.keyword = "";
      // }
    },
    saveDialog() {
      if (this.userArray.length <= 0) {
        this.msg = "Please choose user";
        this.showSnackBar = true;
      } else this.dialog = false;
    },
    Register() {
      this.appLoading = true;
      //   if (this.assetId) {
      this.formData.append("asset_id", this.$route.query.id);
      //   }
      for (let i = 0; i < this.docs.length; i++) {
        this.formData.append("privateFiles", this.docs[i]);
      }
      for (let j = 0; j < this.docsPublic.length; j++) {
        this.formData.append("publicFiles", this.docsPublic[j]);
      }
      this.formData.append("accessibleUserIds", JSON.stringify(this.userArray));
      this.formData.append(
        "ownershipTransferringTo",
        this.manage.ownershipTransferringTo
      );
      this.formData.append("assetID", this.manage.assetID);
      this.formData.append("assetName", this.manage.assetName);
      this.formData.append("description", this.manage.description);
      // this.formData.append("address", this.manage.address);
      // this.formData.append("city", this.manage.city);
      // this.formData.append("state", this.manage.state);
      // this.formData.append("postCode", this.manage.postCode);
      // this.formData.append("titleNumber", this.manage.titleNumber);
      // this.formData.append("surveyNumber", this.manage.surveyNumber);
      // this.formData.append("subDivisionNumber", this.manage.subDivisionNumber);
      // this.formData.append("plotSize", this.manage.plotSize);
      // this.formData.append("landValue", this.manage.landValue);
      // this.formData.append(
      //   "gpsLocationCodes",
      //   JSON.stringify(this.gpsLocationCodes)
      // );
      // this.formData.append("projectStartDate", this.manage.projectStartDate);
      // this.formData.append("projectEndDate", this.manage.projectEndDate);
      // this.formData.append("projectManager", this.manage.projectManager);
      // this.formData.append("ownerName", this.manage.ownerName);
      // this.formData.append(
      //   "ownerBusinessRegistrationNumber",
      //   this.manage.ownerBusinessRegistrationNumber
      // );
      // this.formData.append("ownerIndividualID", this.manage.ownerIndividualID);
      // this.formData.append("ownedOrLeased", this.manage.ownedOrLeased);
      // this.formData.append(
      //   "leaseMonthlyAmount",
      //   this.manage.leaseMonthlyAmount
      // );
      // this.formData.append("leaseTermStart", this.manage.leaseTermStart);
      // this.formData.append("leaseTermEnd", this.manage.leaseTermEnd);
      // this.formData.append("leaseComments", this.manage.leaseComments);
      // this.formData.append("ownerBankName", this.manage.ownerBankName);
      // this.formData.append("ownerBankBranch", this.manage.ownerBankBranch);
      // this.formData.append(
      //   "ownerBankAccountNumber",
      //   this.manage.ownerBankAccountNumber
      // );

      //   this.speciesFlora = this.speciesFlora.map((x) => x.speciesFlora);
      //   this.formData.append("speciesFlora",this.speciesFlora);
      //   this.speciesFauna = this.speciesFauna.map((x) => x.speciesFauna);
      //   this.formData.append("speciesFauna",this.speciesFauna);
      //   this.speciesOther = this.speciesOther.map((x) => x.speciesOther);
      //   this.formData.append("speciesOther",this.speciesOther);
      //   this.formData.append(
      //     "speciesAdditionalComments",this.speciesAdditionalComments
      //   );
      axios({
        method: "POST",
        url: "/asset/transfer",
        data: this.formData,
        headers: {
          token: localStorage.getItem("token"),
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.dialogCheck = false;
            // this.msg = response.data.msg;
            // this.showSnackBar = true;
            this.dialogImage = false;
            this.formData = new FormData();
            this.newMsg = response.data.msg
            this.dialogMessage = true
            // this.$router.push("/assetList");
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.formData = new FormData();
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadDoc(event) {
      this.docs1 = event.target.files || event.dataTransfer.files;
      if (this.docs.length < 10) {
        for (let i = 0; i < this.docs1.length; i++) {
          if (this.fileArray.includes(this.docs1[i].name)) {
            this.msg = "Duplicate File";
            this.showSnackBar = true;
          } else {
            this.docs.push(this.docs1[i]);
            console.log("file", this.docs1[i]);
          }
          this.fileArray.push(this.docs1[i].name);
          this.checkbox = false;
          // this.checkbox1 = false;
          this.checkbox3 = false;
          // this.checkbox2 = false;
          this.docs1[i].sizeCopy = this.docs1[i].size + "Bytes";
          if (this.docs1[i].size >= 1024) {
            this.byte = this.docs1[i].size;
            this.kb = this.byte / 1024;
            if (this.kb >= 1024) {
              this.mb = (this.byte / 1048576).toFixed(2);
              if (this.mb >= 1024) {
                this.gb = (this.byte / 1073741824).toFixed(2);
                this.docs1[i].sizeCopy = this.gb + "GB";
              } else {
                this.docs1[i].sizeCopy = this.mb + "MB";
              }
            } else {
              this.docs1[i].sizeCopy = this.kb.toFixed(2) + "KB";
            }
          }
        }
      } else {
        this.msg = "Maximum 10 can be uploaded";
        this.showSnackBar = true;
      }
    },
    removePrivate(i) {
      this.fileArray = this.fileArray.filter((x) => this.docs[i].name != x);
      this.docs.splice(i, 1);
      this.checkbox = false;
      this.checkbox3 = false;
    },
    uploadDocPublic(event) {
      this.docs1Public = event.target.files || event.dataTransfer.files;

      if (this.docsPublic.length < 10) {
        for (let i = 0; i < this.docs1Public.length; i++) {
          if (this.fileArray.includes(this.docs1Public[i].name)) {
            this.msg = "Duplicate File";
            this.showSnackBar = true;
          } else {
            this.docsPublic.push(this.docs1Public[i]);
          }
          this.fileArray.push(this.docs1Public[i].name);
          // this.checkbox = false;
          this.checkbox1 = false;
          this.checkbox3 = false;
          this.checkbox2 = false;
          this.docs1Public[i].sizeCopy = this.docs1Public[i].size + "Bytes";
          if (this.docs1Public[i].size >= 1024) {
            this.byte = this.docs1Public[i].size;
            this.kb = this.byte / 1024;
            if (this.kb >= 1024) {
              this.mb = (this.byte / 1048576).toFixed(2);
              if (this.mb >= 1024) {
                this.gb = (this.byte / 1073741824).toFixed(2);
                this.docs1Public[i].sizeCopy = this.gb + "GB";
              } else {
                this.docs1Public[i].sizeCopy = this.mb + "MB";
              }
            } else {
              this.docs1Public[i].sizeCopy = this.kb.toFixed(2) + "KB";
            }
          }
        }
      } else {
        this.msg = "Maximum 10 can be uploaded";
        this.showSnackBar = true;
      }
    },
    removePublic(i) {
      this.fileArray = this.fileArray.filter(
        (x) => this.docsPublic[i].name != x
      );
      this.docsPublic.splice(i, 1);
      this.checkbox1 = false;
      this.checkbox3 = false;
      this.checkbox2 = false;
    },
  },
};
</script>
