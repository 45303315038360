<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <div style="background-color: #ebf0ff">
      <v-layout wrap justify-center pt-5 pb-10>
        <v-flex xs12 sm12 md10>
          <v-tabs
            v-model="tabValue"
            color="#7CB9E8"
            grow
            dark
            center-active
            centered
            :show-arrows="true"
            active-class="activ"
            class="nonactiv"
            background-color="#ebf0ff"
            style="font-family: robotoRegular"
          >
            <v-tab style="color: #000" dark href="#tab-1">Transfer Details</v-tab>
            <!-- <v-tab style="color: #000" dark href="#tab-2">MANAGEMENT</v-tab> -->
            <!-- <v-tab style="color: #000" dark href="#tab-3">Species</v-tab> -->
            <v-tab style="color: #000" dark href="#tab-4"
              >Supporting files</v-tab
            >
            <v-tab-item value="tab-1">
              <v-card flat style="background-color: #ebf0ff">
                <Page1 @stepper="winStepper" :asset="asset" />
              </v-card>
            </v-tab-item>
            <!-- <v-tab-item value="tab-2">
              <v-card
                flat
                style="background-color: #ebf0ff"
                v-if="subLevel.includes(1)"
              >
                <Page2
                  value="tab-2"
                  @stepper="winStepper"
                  :asset="asset"
                  :manage="manage"
                  :assetIdNew="assetIdNew"
                />
              </v-card>
              <v-card
                flat
                style="background-color: #ebf0ff"
                height="580px"
                v-else
              >
                <v-layout wrap justify-center py-16>
                  <v-flex xs12 pt-10>
                    <span style="font-family: sedanRegular; font-size: 20px"
                      >Please complete Asset Details to proceed with
                      Management</span
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-tab-item> -->
            <!-- <v-tab-item value="tab-3">
              <v-card
                flat
                style="background-color: #ebf0ff"
                v-if="subLevel.includes(1)"
              >
                <Page3
                  value="tab-3"
                  @stepper="winStepper"
                  :asset="asset"
                  :assetIdNew="assetIdNew"
                  :species="species"
                />
              </v-card>
              <v-card
                flat
                style="background-color: #ebf0ff"
                height="580px"
                v-else
              >
                <v-layout wrap justify-center py-16>
                  <v-flex xs12 pt-10>
                    <span style="font-family: sedanRegular; font-size: 20px"
                      >Please complete Asset Details to proceed with
                      Species</span
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-tab-item> -->
            <v-tab-item value="tab-4">
              <v-card
                flat
                style="background-color: #ebf0ff"
                v-if="
                  subLevel.includes(1) &&
                  subLevel.includes(2) &&
                  subLevel.includes(3)
                "
              >
                <Page4
                  value="tab-4"
                  :asset="asset"
                  :manage="manage"
                  :species="species"
                  :gpsLocationCodes="gpsLocationCodes"
                  :speciesFlora="speciesFlora"
                  :speciesFauna="speciesFauna"
                  :speciesOther="speciesOther"
                  :speciesAdditionalComments="speciesAdditionalComments"
                  :assetIdNew="assetIdNew"
                  :subLevel="subLevel"
                  :users="users"
                />
              </v-card>
              <v-card
                flat
                style="background-color: #ebf0ff"
                height="580px"
                v-else
              >
                <v-layout wrap justify-center py-16>
                  <v-flex xs12 pt-10>
                    <span style="font-family: sedanRegular; font-size: 20px"
                      >Please complete previous 3 pages</span
                    >
                  </v-flex>
                </v-layout>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>
<script>
// import axios from "axios";
import Page1 from "./page1";
// import Page2 from "./page2";
// import Page3 from "./page3";
import Page4 from "./page4";
export default {
  components: {
    Page1,
    // Page2,
    // Page3,
    Page4,
  },
  data: () => ({
    appLoading: false,
    ServerError: false,
    tabValue: "tab-1",
    asset: {},
    manage: {},
    gpsLocationCodes: {},
    speciesFlora: {},
    speciesFauna: {},
    speciesOther: {},
    speciesAdditionalComments: "",
    newspeciesFlora: [],
    assetId: "",
    assetIdNew: "",
    users:"",
    species: {},
    files: {},
    check: [],
    subLevel: [],
    items: ["Transfer Details", "MANAGEMENT", "Supporting files"],
  }),
  methods: {
    AddField: function () {
      this.location.push({ latitude: "", longitude: "" });
    },
    speciesAdd: function () {
      this.species.push({ speciesFlora: "", speciesAnimals: "" });
    },
    winStepper(item) {
      this.flag = false;
      window.scrollTo(0, 0);
      if (item.ref == "page1") {
        if (item.assetDetails && item.gpsLocationCodes) {
          (this.asset = item.assetDetails),
            (this.gpsLocationCodes = item.gpsLocationCodes);
          console.log("asset", this.assetDetails);
        }
      }
      if (item.ref == "page1Tab") {
        if (
          item.level ||
          item.check ||
          item.subLevel ||
          item.gpsLocationCodes || item.assetDetails
        ) {
          this.tabValue = item.level;
          this.check = item.check;
          this.gpsLocationCodes = item.gpsLocationCodes;
          this.subLevel.push(item.subLevel);
          this.asset=item.assetDetails
        }
      }
      if (item.ref == "page1SubTab") {
        if (item.subLevel) {
          this.subLevel = item.subLevel;
        }
      }
      if (item.ref == "page2Tab") {
        if (item.level || item.subLevel || item.management) {
          this.tabValue = item.level;
          this.subLevel.push(item.subLevel);
          this.manage=item.management
        }
      }
      if (item.ref == "page3Tab") {
        if (
          item.level ||
          item.subLevel ||
          item.speciesFlora ||
          item.speciesFauna ||
          item.speciesOther ||
          item.speciesAdditionalComments
        ) {
          this.tabValue = item.level;
          this.subLevel.push(item.subLevel);
          this.speciesFlora = item.speciesFlora;
          this.speciesFauna = item.speciesFauna;
          this.speciesOther = item.speciesOther;
          this.speciesAdditionalComments = item.speciesAdditionalComments;
        }
      }
      if (item.ref == "page2") {
        if (item.management) {
          this.manage = item.management;
        }
      }
      if (item.ref == "managementPage") {
        if (item.management || item.assetIdNew || item.species || item.files || item.users) {
          this.manage = item.management;
          this.assetIdNew = item.assetIdNew;
          this.species = item.species;
          this.files = item.files;
          this.users=item.users
        }
      }
      if (item.ref == "assetGet") {
        if (item.assetIdNew) {
          this.assetIdNew = item.assetIdNew;
        }
      }
      if (item.ref == "assetGetPage2") {
        if (item.assetIdNew) {
          this.assetIdNew = item.assetIdNew;
        }
      }
      if (item.ref == "assetPage1") {
        if (item.assetDetails) {
          this.asset = item.assetDetails;
        }
      }
      if (item.ref == "page3") {
        if (
          item.speciesFlora ||
          item.speciesFauna ||
          item.speciesOther ||
          item.speciesAdditionalComments
        ) {
          this.speciesFlora = item.speciesFlora;
          this.speciesFauna = item.speciesFauna;
          this.speciesOther = item.speciesOther;
          this.speciesAdditionalComments = item.speciesAdditionalComments;
          this.newspeciesFlora = this.speciesFlora.map((x) => x.speciesFlora);
        }
      }
    },
  },
};
</script>
<style scoped>
.activ {
  color: black !important;
  font-family: robotoRegular !important;
}
.nonactiv {
  color: #acacac !important;
  font-family: robotoRegular !important;
}
</style>
